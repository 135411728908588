import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './styles/global.scss';
import { AuthProvider } from './user-auth/SlideInAuthContext';
import { addLocale, locale } from 'primereact/api';

addLocale('pt', {
  startsWith: 'Começa com',
  contains: 'Contém',
  notContains: 'Não contém',
  endsWith: 'Termina com',
  equals: 'Igual',
  notEquals: 'Diferente',
  noFilter: 'Sem filtro',
  lt: 'Menor que',
  lte: 'Menor que ou igual a',
  gt: 'Maior que',
  gte: 'Maior que ou igual a',
  dateIs: 'Data é',
  dateIsNot: 'Data não é',
  dateBefore: 'Date é anterior',
  dateAfter: 'Data é posterior',
  custom: 'Customizado',
  clear: 'Limpar',
  apply: 'Aplicar',
  matchAll: 'Match All',
  matchAny: 'Match Any',
  addRule: 'Adicionar Regra',
  removeRule: 'Remover Regra',
  accept: 'Sim',
  reject: 'Não',
  choose: 'Escolha',
  upload: 'Upload',
  cancel: 'Cancelar',
  dayNames: [
    'Domingo',
    'Segunda-Feira',
    'Terça-Feira',
    'Quarta-Feira',
    'Quinta-Feira',
    'Sexta-Feira',
    'Sábado',
  ],
  dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  today: 'Hoje',
  weekHeader: 'Sem',
  firstDayOfWeek: 1,
  dateFormat: 'dd/mm/yy',
  weak: 'Fraco',
  medium: 'Médio',
  strong: 'Forte',
  passwordPrompt: 'Digite uma Password',
  emptyFilterMessage: 'Sem opções disponíveis',
  emptyMessage: 'Sem resultados',
  aria: {
    trueLabel: 'Verdadeiro',
    falseLabel: 'Falso',
    nullLabel: 'Não selecionado',
    pageLabel: 'Página',
    firstPageLabel: 'Primeira Página',
    lastPageLabel: 'Última Página',
    nextPageLabel: 'Próxima',
    previousPageLabel: 'Anterior',
  },
});
locale('pt');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
