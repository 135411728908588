import { useAuth } from '../user-auth/SlideInAuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { GrupoEstadoEnum, ViagemItem } from '../slidein_api';
import FullPageLoader from '../layout/FullPageLoader';
import { MessageContext } from '../layout/context/MessageContext';
import { PrimeIcons } from 'primereact/api';
import { Divider } from 'primereact/divider';
import { Tag } from 'primereact/tag';

export function Home() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const { clearAll } = useContext(MessageContext);

  useEffect(() => {
    clearAll();
    if (!user) {
      return;
    }
    if (searchParams.get('codgrupo')) {
      navigate(`/nova_viagem?codgrupo=${searchParams.get('codgrupo')}`);
    }
  }, [user]);

  const pvalue = (viagem: ViagemItem) => {
    if (viagem.cancelada) {
      return 'Cancelada';
    }
    if (viagem.esta_paga) {
      return 'Paga';
    } else if (viagem.bloqueada_pagamentos) {
      return 'Inscrição suspensa. Prazo de pagamento expirado.';
    } else if (viagem.prestacoes_em_atraso) {
      return 'Prestações em Atraso';
    } else {
      return 'A decorrer';
    }
  };
  const svalue = (viagem: ViagemItem) => {
    if (viagem.cancelada) {
      return 'danger';
    }
    if (viagem.esta_paga) {
      return 'success';
    } else if (viagem.prestacoes_em_atraso) {
      return 'danger';
    } else if (viagem.bloqueada_pagamentos) {
      return 'danger';
    } else {
      return 'info';
    }
  };
  const ivalue = (viagem: ViagemItem) => {
    if (viagem.cancelada) {
      return '--';
    }
    if (viagem.esta_paga) {
      return PrimeIcons.CHECK;
    } else if (viagem.prestacoes_em_atraso || viagem.bloqueada_pagamentos) {
      return PrimeIcons.EXCLAMATION_CIRCLE;
    } else {
      return 'pi pi-hourglass';
    }
  };

  const rowClass = (viagem: ViagemItem) => {
    return (
      <div className='flex flex-row align-items-center justify-content-center my-1'>
        <div className='flex flex-column gap-1 w-full'>
          <div className={'text-2xl font-bold'}>
            {viagem.campanha.nome}&nbsp;&nbsp;&nbsp;
            <Tag
              value={pvalue(viagem)}
              severity={svalue(viagem)}
              icon={ivalue(viagem)}
            />
          </div>
          <div className={'text-xl font-bold'}>Destino: {viagem.campanha.destino}</div>
          <div className={'text-overflow-ellipsis white-space-normal mx-2'}>
            {viagem.grupo.instituicao}
            {viagem.id}
          </div>
          <div className={'text-overflow-ellipsis white-space-normal mx-2'}>
            {viagem.campanha.data_inicio_viagem + ' a ' + viagem.campanha.data_fim_viagem}(
            {viagem.campanha.nr_noites} noites)
          </div>

          <div className='flex flex-shrink-1 flex-column gap-2 justify-content-end'>
            <div className={'flex flex-row justify-content-end'}></div>
            {viagem.grupo.estado != GrupoEstadoEnum.FECHADO &&
              viagem.grupo.estado != GrupoEstadoEnum.SUSPENSO &&
              !(viagem.cancelada || viagem.bloqueada_pagamentos) && (
                <div className={'flex flex-row justify-content-end mb-1'}>
                  <Button
                    icon={PrimeIcons.ARROW_RIGHT}
                    iconPos={'right'}
                    outlined
                    label={'Detalhes'}
                    onClick={() => navigate('aminha_viagem/' + viagem.id)}></Button>
                </div>
              )}{' '}
            {viagem.grupo.estado == GrupoEstadoEnum.FECHADO &&
              !(viagem.cancelada || viagem.bloqueada_pagamentos) && (
                <div className={'flex flex-row justify-content-end mb-1'}>
                  <Button
                    icon={PrimeIcons.ARROW_RIGHT}
                    iconPos={'right'}
                    outlined
                    label={'Detalhes'}
                    onClick={() => navigate('a_minhaviagem_o/' + viagem.id)}></Button>
                </div>
              )}
          </div>
          <Divider></Divider>
        </div>
      </div>
    );
  };

  if (!user) {
    return <FullPageLoader />;
  }
  return (
    <Card className={'w-full sm:w-4 p-3 m-auto'}>
      <div className='flex flex-column flex-wrap justify-content-between gap-3'>
        <div className='flex flex-column '>
          <h1>As Minhas Viagens </h1>
          <Divider></Divider>
          <div className={'flex flex-column justify-content-between gap-1'}>
            {user.viagens.map((vi) => rowClass(vi))}
          </div>
        </div>
        <Divider></Divider>

        <div className={'flex flex-column my-auto'}>
          <h3>Registo em nova viagem</h3>
          <Button
            outlined
            label={'Nova Viagem'}
            onClick={() => {
              navigate('/nova_viagem');
            }}></Button>
        </div>
      </div>
    </Card>
  );
}
