import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ApiError, PasswordResetConfirmRetypeRequest, UtilizadorService } from '../slidein_api';
import { Card } from 'primereact/card';
import React, { useContext, useState } from 'react';
import { classNames } from 'primereact/utils';
import { getFormErrorMessage, isFormFieldValid } from '../helpers';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { PrimeIcons } from 'primereact/api';
import { MessageContext } from '../layout/context/MessageContext';
import FullPageLoader from '../layout/FullPageLoader';

export function NovaPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { msgSuccess, msgWarn, msgError } = useContext(MessageContext);

  const { uid, token } = useParams();

  const pform = useFormik<PasswordResetConfirmRetypeRequest>({
    initialValues: {
      uid: uid,
      token: token,
      new_password: '',
      re_new_password: '',
    },

    onSubmit: async (pass: PasswordResetConfirmRetypeRequest) => {
      setLoading(true);
      UtilizadorService.utilizadorResetPasswordConfirmCreate({ requestBody: pass }).then(
        () => {
          setLoading(false);
          msgSuccess('A password foi alterada com sucesso!');
          navigate('/');
        },
        (reason: ApiError) => {
          setLoading(false);
          if (reason.body['uid'] !== undefined || reason.body['token'] !== undefined) {
            msgWarn('O teu link deve ter expirado! Tenta de novo.');
            navigate('/');
          } else {
            msgError('Algo correu mal, verificar a informação submetida! Tenta de Novo!');
            pform.setErrors(reason.body);
          }
        }
      );
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .min(8, 'Tamanho mínimo 8 caracteres')
        .max(20, 'Tamanho máximo 20 caracteres')
        .required('Obrigatório'),
      re_new_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'As passwords não coincidem!')
        .min(8, 'Tamanho mínimo 8 caracteres')
        .max(20, 'Tamanho máximo 20 caracteres')
        .required('Obrigatório'),
    }),
  });
  if (loading) {
    return <FullPageLoader />;
  }
  return (
    <div className='flex align-items-center justify-content-center'>
      <Card className={'max-w-full sm:max-w-min sm:min-w-max m-auto'}>
        <div className={'sm:px-6'}>
          <h2>Nova password</h2>
          <h4>Define a nova password:</h4>
          <form onSubmit={pform.handleSubmit}>
            <div className='field'>
              <label
                htmlFor='new_password'
                className={classNames({
                  'p-error': isFormFieldValid<PasswordResetConfirmRetypeRequest>(
                    'new_password',
                    pform
                  ),
                })}>
                Nova Password*
              </label>
              <br />
              <Password
                id='new_password'
                name='new_password'
                toggleMask
                feedback={true}
                value={pform.values.new_password}
                onChange={pform.handleChange}
                autoFocus
                className={classNames({
                  'p-invalid': isFormFieldValid<PasswordResetConfirmRetypeRequest>(
                    'new_password',
                    pform
                  ),
                })}
              />
              <br />
              {getFormErrorMessage<PasswordResetConfirmRetypeRequest>('new_password', pform)}
            </div>
            <div className='field'>
              <label
                htmlFor='re_new_password'
                className={classNames({
                  'p-error': isFormFieldValid<PasswordResetConfirmRetypeRequest>(
                    're_new_password',
                    pform
                  ),
                })}>
                Repetir Password*
              </label>
              <br />
              <Password
                id='re_new_password'
                name='re_new_password'
                toggleMask
                feedback={false}
                value={pform.values.re_new_password}
                onChange={pform.handleChange}
                className={classNames({
                  'p-invalid': isFormFieldValid<PasswordResetConfirmRetypeRequest>(
                    're_new_password',
                    pform
                  ),
                })}
              />
              <br />
              {getFormErrorMessage<PasswordResetConfirmRetypeRequest>('re_new_password', pform)}
            </div>
            <br />
            <div className='flex flex-row justify-content-center'>
              <Button
                type={'submit'}
                severity='info'
                raised
                icon={PrimeIcons.LOCK}
                outlined
                className={'text-xl py-2 px-3'}
                label={'Criar Nova Password'}></Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}
