import { PrimeIcons } from 'primereact/api';
import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Messages } from 'primereact/messages';

type MessageContextType = {
  message;
  toast;
  msgError;
  msgInfo;
  msgWarn;
  msgSuccess;
  toastError;
  toastInfo;
  toastWarn;
  toastSuccess;

  clearAll;
};

export const MessageContext = React.createContext<Partial<MessageContextType>>({});

export function MessageProvider(props: { children }) {
  const message = useRef<Messages>(null);
  const toast = useRef<Toast>(null);

  const _show_msg = (
    title: string = '',
    msg: string,
    sticky: boolean = false,
    closable: boolean = true,
    clear: boolean = true,
    severity,
    icon = ''
  ) => {
    if (clear) {
      message.current.replace({
        sticky: sticky,
        severity: severity,
        summary: title,
        detail: msg,
        closable: closable,
        icon: icon,
      });
    } else {
      message.current.show({
        sticky: sticky,
        severity: severity,
        summary: title,
        detail: msg,
        closable: closable,
        icon: icon,
      });
    }
  };

  const msgError = (
    title: string = '',
    msg: string = '',
    sticky: boolean = false,
    clear: boolean = true
  ) => {
    _show_msg(title, msg, sticky, sticky, clear, 'error', PrimeIcons.TIMES_CIRCLE);
  };
  const msgInfo = (
    title: string = '',
    msg: string = '',
    sticky: boolean = false,
    clear: boolean = true
  ) => {
    _show_msg(title, msg, sticky, sticky, clear, 'info', PrimeIcons.INFO_CIRCLE);
  };
  const msgWarn = (
    title: string = '',
    msg: string = '',
    sticky: boolean = false,
    clear: boolean = true
  ) => {
    _show_msg(title, msg, sticky, sticky, clear, 'warn', PrimeIcons.EXCLAMATION_CIRCLE);
  };
  const msgSuccess = (
    title: string = '',
    msg: string = '',
    sticky: boolean = false,
    clear: boolean = true
  ) => {
    _show_msg(title, msg, sticky, sticky, clear, 'success', PrimeIcons.CHECK_CIRCLE);
  };

  const _showToast = (title: string = '', msg: string, sticky: boolean = false, severity) => {
    toast.current.show({
      sticky: sticky,
      severity: severity,
      summary: title,
      detail: msg,
      closable: sticky,
    });
  };

  const toastError = (title: string, msg: string, sticky) => {
    _showToast(title, msg, sticky, 'error');
  };
  const toastInfo = (title: string, msg: string, sticky) => {
    _showToast(title, msg, sticky, 'info');
  };
  const toastWarn = (title: string, msg: string, sticky) => {
    _showToast(title, msg, sticky, 'warn');
  };
  const toastSuccess = (title: string, msg: string, sticky) => {
    _showToast(title, msg, sticky, 'success');
  };

  const clearAll = () => {
    message.current.clear();
    toast.current.clear();
  };

  const value = {
    message,
    toast,
    msgError,
    msgInfo,
    msgWarn,
    msgSuccess,
    toastError,
    toastInfo,
    toastWarn,
    toastSuccess,
    clearAll,
  };

  return <MessageContext.Provider value={value}> {props.children} </MessageContext.Provider>;
}
