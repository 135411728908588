import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from './user-auth/SlideInAuthContext';
import React, { ReactElement } from 'react';
import { Login } from './pages/Login';
import Layout from './layout/Layout';
import { LayoutProvider } from './layout/context/LayoutContext';
import { PerfilCliente } from './pages/PerfilCliente';
import { Convite } from './pages/Convite';
import { NovaPassword } from './pages/NovaPassword';
import { Home } from './pages/Home';
import { ViagemView } from './pages/ViagemView';
import { ViagemComprar } from './pages/ViagemComprar';
import { ChefeView } from './pages/ChefeView';
import { MessageProvider } from './layout/context/MessageContext';
import { OpenAPI } from './slidein_api';
import { ViagemFechadaView } from './pages/ViagemFechadaView';

function App() {
  const { isAuthenticated, token } = useAuth();
  OpenAPI.BASE = process.env.REACT_APP_API_URL || '';
  OpenAPI.TOKEN = async () => {
    if (token) {
      return token.access;
    } else {
      return undefined;
    }
  };

  let routes: ReactElement;
  if (isAuthenticated) {
    routes = (
      <Routes>
        <Route
          path='/'
          element={<Home />}
        />
        <Route
          path='convite'
          element={<Convite />}
        />
        <Route
          path='nova_viagem'
          element={<ViagemComprar />}
        />
        <Route
          path={'editar_perfil'}
          element={<PerfilCliente modoEdit={true} />}
        />
        <Route
          path={'aminha_viagem/:viagemID'}
          element={<ViagemView />}
        />
        <Route
          path={'a_minhaviagem_o/:viagemID'}
          element={<ViagemFechadaView />}
        />
        <Route
          path={'chefeviagem/:grupoID'}
          element={<ChefeView />}
        />

        <Route
          path='*'
          element={
            <Navigate
              replace
              to='/'
            />
          }
        />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route
          path=''
          element={<Login />}
        />
        <Route
          path='convite'
          element={<Convite />}
        />
        <Route
          path={'registar'}
          element={<PerfilCliente modoEdit={false} />}
        />
        <Route
          path={'recpass/:uid/:token'}
          element={<NovaPassword />}
        />
        <Route
          path='*'
          element={
            <Navigate
              replace
              to='/'
            />
          }
        />
      </Routes>
    );
  }

  return (
    <MessageProvider>
      <LayoutProvider>
        <Layout>{routes}</Layout>
      </LayoutProvider>
    </MessageProvider>
  );
}

export default App;
