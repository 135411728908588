import { classNames } from 'primereact/utils';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { LayoutContext } from './context/LayoutContext';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../user-auth/SlideInAuthContext';
import { MessageContext } from './context/MessageContext';
import { ApiError, UtilizadorService } from '../slidein_api';
import { SlideinLogo } from '../helpers';

const AppTopbar = forwardRef((props, ref) => {
  const { layoutConfig, toggleColorScheme, layoutState, showProfileSidebar } =
    useContext(LayoutContext);
  const { msgError } = useContext(MessageContext);
  const { isAuthenticated, logout, user, setUser } = useAuth();
  // const [user, setUser] = useState<Cliente>(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      UtilizadorService.utilizadorMeRetrieve().then(
        (value) => setUser(value),
        (reason: ApiError) => {
          if (reason.status === 401) {
            msgError('A sua sessão expirou, volte a fazer Login!');
          } else {
            msgError('O site encontra-se em manutenção! Tente Mais tarde. Obrigado.');
          }
          logout();
        }
      );
    } else {
      setUser(undefined);
    }
  }, [isAuthenticated]);

  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current,
  }));

  return (
    <div className='layout-topbar'>
      <Link to='/'>
        <div className='layout-topbar-logo'>
          <SlideinLogo />
        </div>
      </Link>

      <button
        ref={topbarmenubuttonRef}
        type='button'
        className='p-link layout-topbar-menu-button layout-topbar-button'
        onClick={showProfileSidebar}>
        <i className='pi pi-ellipsis-v' />
      </button>

      <div
        ref={topbarmenuRef}
        className={classNames('layout-topbar-menu', {
          'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible,
        })}>
        <button
          className='p-link layout-topbar-button'
          onClick={() => toggleColorScheme()}>
          <i
            className={classNames('pi', {
              'pi-moon': layoutConfig.colorScheme === 'dark',
              'pi-sun yellow': layoutConfig.colorScheme === 'light',
            })}
          />
          <span>Modo Dark/Light</span>
        </button>

        {user && (
          <>
            <button
              disabled={true}
              className='p-link layout-topbar-button'>
              <i className='pi pi-user' />
              <div className={'mx-2 text-lg font-semibold text-color-secondary'}>
                {user.nome_simples}
              </div>
            </button>
            <button
              onClick={() => navigate('editar_perfil')}
              className='p-link layout-topbar-button'>
              <i className='pi pi-user-edit' />
              <div className={'mx-2 text-lg font-semibold text-color-secondary'}>Editar Perfil</div>
            </button>
            <button
              onClick={() => logout()}
              type='button'
              className='p-link layout-topbar-button'>
              <i className='pi pi-sign-out'></i>
              <span>Sair</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
});
AppTopbar.displayName = 'AppTopBar';

export default AppTopbar;
