import React, { useState } from 'react';

type LayoutContextType = {
  layoutConfig;
  layoutState;
  setLayoutState;
  onMenuToggle;
  showProfileSidebar;
  toggleColorScheme;
  setLayoutConfig;
};

export const LayoutContext = React.createContext<Partial<LayoutContextType>>({});

export function LayoutProvider(props: { children }) {
  const [layoutConfig, setLayoutConfig] = useState({
    colorScheme: window.localStorage.getItem('colorScheme') || 'dark',
  });

  const [layoutState, setLayoutState] = useState({
    staticMenuDesktopInactive: false,
    profileSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
  });

  const onMenuToggle = () => {
    if (isDesktop()) {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive,
      }));
    } else {
      setLayoutState((prevLayoutState) => ({
        ...prevLayoutState,
        staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
      }));
    }
  };

  const toggleColorScheme = () => {
    setLayoutConfig((preLayoutConfig) => ({
      ...preLayoutConfig,
      colorScheme: preLayoutConfig.colorScheme === 'dark' ? 'light' : 'dark',
    }));
  };

  const showProfileSidebar = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      profileSidebarVisible: !prevLayoutState.profileSidebarVisible,
    }));
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const value = {
    layoutConfig,
    setLayoutConfig,
    layoutState,
    setLayoutState,
    toggleColorScheme,
    onMenuToggle,
    showProfileSidebar,
  };

  return <LayoutContext.Provider value={value}> {props.children} </LayoutContext.Provider>;
}
