import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { useAuth } from '../user-auth/SlideInAuthContext';
import { PrimeIcons } from 'primereact/api';

export function Convite() {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (searchParams.get('codgrupo') === null) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      navigate(`/nova_viagem?codgrupo=${searchParams.get('codgrupo')}`);
    }
  }, [navigate, searchParams, user]);

  return (
    <div className='flex align-items-center justify-content-center'>
      <Card className={'max-w-full sm:max-w-min sm:min-w-max m-auto'}>
        <div className='mx-2 sm:mx-6'>
          <h1>Olá,</h1>
          <h2>Já estás registado na nossa plataforma?</h2>
          <div className='flex flex-row flex-wrap justify-content-between '>
            <div className='flex flex-column my-4 '>
              <h3>Se sim:</h3>
              <Link to={'/?codgrupo=' + searchParams.get('codgrupo')}>
                <Button
                  icon={PrimeIcons.LOCK}
                  raised
                  severity='success'
                  className={'text-xl py-2 px-3'}
                  outlined
                  label={'Fazer Login'}></Button>
              </Link>
            </div>
            <div className='flex flex-column my-4'>
              <h3>Ainda não:</h3>
              <Link to={'/registar?codgrupo=' + searchParams.get('codgrupo')}>
                <Button
                  severity='info'
                  raised
                  icon={PrimeIcons.PENCIL}
                  outlined
                  className={'text-xl py-2 px-3'}
                  label={'Registar'}></Button>
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
