import { useEventListener, useUnmountEffect } from 'primereact/hooks';
import { classNames, DomHandler } from 'primereact/utils';
import React, { useContext, useEffect, useRef } from 'react';
import AppFooter from './AppFooter';
import AppTopbar from './AppTopbar';
import { LayoutContext } from './context/LayoutContext';
import { useLocation } from 'react-router-dom';
import { MessageContext } from './context/MessageContext';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { Toast } from 'primereact/toast';

import { Messages } from 'primereact/messages';

function Layout(props: { children }) {
  const { layoutConfig, layoutState, setLayoutState } = useContext(LayoutContext);
  const { toast, message } = useContext(MessageContext);
  const topbarRef = useRef(null);
  const sidebarRef = useRef(null);
  const location = useLocation();
  const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
    type: 'click',
    listener: (event: MouseEvent) => {
      const isOutsideClicked = !(
        sidebarRef.current === event.target ||
        sidebarRef.current.contains(event.target) ||
        topbarRef.current.menubutton === event.target ||
        topbarRef.current.menubutton.contains(event.target)
      );

      if (isOutsideClicked) {
        hideMenu();
      }
    },
  });

  const [bindProfileMenuOutsideClickListener, unbindProfileMenuOutsideClickListener] =
    useEventListener({
      type: 'click',
      listener: (event) => {
        const isOutsideClicked = !(
          topbarRef.current.topbarmenu === event.target ||
          topbarRef.current.topbarmenu.contains(event.target) ||
          topbarRef.current.topbarmenubutton === event.target ||
          topbarRef.current.topbarmenubutton.contains(event.target)
        );

        if (isOutsideClicked) {
          hideProfileMenu();
        }
      },
    });

  useEffect(() => {
    const themeLink: HTMLLinkElement | null = document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      if (layoutConfig.colorScheme === 'dark') {
        themeLink.href = '/themes/arya-green/theme.css';
      } else {
        themeLink.href = '/themes/saga-green/theme.css';
      }
    }
    window.localStorage.setItem('colorScheme', layoutConfig.colorScheme);
  }, [layoutConfig.colorScheme]);

  const hideMenu = () => {
    setLayoutState((prevLayoutState) => ({
      ...prevLayoutState,
      staticMenuMobileActive: false,
      menuHoverActive: false,
    }));
    unbindMenuOutsideClickListener();
    unblockBodyScroll();
  };

  const hideProfileMenu = () => {
    setLayoutState((prevLayoutState) => ({ ...prevLayoutState, profileSidebarVisible: false }));
    unbindProfileMenuOutsideClickListener();
  };

  const blockBodyScroll = () => {
    DomHandler.addClass(this, 'blocked-scroll');
  };

  const unblockBodyScroll = () => {
    DomHandler.removeClass(this, 'blocked-scroll');
  };

  useEffect(() => {
    if (layoutState.staticMenuMobileActive) {
      bindMenuOutsideClickListener();
    }

    layoutState.staticMenuMobileActive && blockBodyScroll();
  }, [layoutState.staticMenuMobileActive]);

  useEffect(() => {
    if (layoutState.profileSidebarVisible) {
      bindProfileMenuOutsideClickListener();
    }
  }, [layoutState.profileSidebarVisible]);

  useEffect(() => {
    hideMenu();
    hideProfileMenu();
  }, [location]);

  useUnmountEffect(() => {
    unbindMenuOutsideClickListener();
    unbindProfileMenuOutsideClickListener();
  });

  const containerClass = classNames('layout-wrapper', {
    'layout-theme-light': layoutConfig.colorScheme === 'light',
    'layout-theme-dark': layoutConfig.colorScheme === 'dark',
    'layout-static-inactive': layoutState.staticMenuDesktopInactive,
    'layout-mobile-active': layoutState.staticMenuMobileActive,
    'p-input-filled': layoutConfig.inputStyle === 'filled',
    'p-ripple-disabled': !layoutConfig.ripple,
  });

  return (
    <React.Fragment>
      <div className={containerClass}>
        <AppTopbar ref={topbarRef} />

        <div className={'layout-main-container sidebar-disable'}>
          <Messages ref={message} />
          <Toast
            ref={toast}
            position={'top-center'}
          />
          <ConfirmDialog />
          <div className='layout-main'>{props.children}</div>
          <AppFooter />
        </div>
        <div className='layout-mask'></div>
      </div>
    </React.Fragment>
  );
}

export default Layout;
