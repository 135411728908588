import React from 'react';
import { Link } from 'react-router-dom';
import { InstaLogo, SlideinLogo, TiktokLogo } from '../helpers';

function AppFooter() {
  return (
    <div className='layout-footer w-full'>
      <div className='flex flex-column w-10 m-auto'>
        <div className='flex flex-row justify-content-between'>
          <div className={'flex align-content-center'}>
            <span className='text-xl ml-2 align-self-center'>
              <Link
                to={'//slideintravel.com'}
                target={'_blank'}>
                <div className={'layout-footer-logo'}>
                  <SlideinLogo />
                </div>
              </Link>
            </span>
          </div>
          <div>
            <Link
              to={'https://www.tiktok.com/' + process.env.REACT_APP_TIKTOK_HANDLE}
              target={'_blank'}>
              <TiktokLogo />
            </Link>
            <Link
              to={'https://www.instagram.com/' + process.env.REACT_APP_INSTAGRAM_HANDLE}
              target={'_blank'}>
              <InstaLogo />
            </Link>
          </div>
        </div>
        <p className={'text-sm font-italic text-color-secondary'}>
          ©Copyright 2023 - SLIDE IN - AGÊNCIA DE VIAGENS E TURISMO LDA | RNAVT nº2793
        </p>
      </div>
    </div>
  );
}

export default AppFooter;
