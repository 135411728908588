import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../user-auth/SlideInAuthContext';
import { classNames } from 'primereact/utils';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ApiError, SendEmailResetRequest, UtilizadorService } from '../slidein_api';
import { Password } from 'primereact/password';
import { MessageContext } from '../layout/context/MessageContext';
import { SlideinLogo } from '../helpers';
import FullPageLoader from '../layout/FullPageLoader';

export function Login() {
  const { login } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { msgWarn, msgError, clearAll } = useContext(MessageContext);
  const [searchParams] = useSearchParams();
  const [grupoCode, setGrupoCode] = useState('');
  useEffect(() => {
    clearAll();
    const cg = searchParams.get('codgrupo');
    if (cg) {
      setGrupoCode('/?codgrupo=' + cg);
    } else {
      setGrupoCode('');
    }
  }, []);

  const [recuperarMode, setRecuperarMode] = useState<boolean>(false);
  const [emailRecuperarEnviado, setEmailRecuperarEnviado] = useState<boolean>(false);

  const loginForm = useFormik<{ email: string; password: string }>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Obrigatório').trim().email('Email Inválido!'),
      password: Yup.string().required('Obrigatório'),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      login(values.email.trim(), values.password).then(
        (value) => {
          if (!value) {
            msgError('Acesso Negado! Reveja as suas credenciais!', true);
            loginForm.setFieldError('email', 'Acesso Negado! Reveja as suas credenciais!');
            loginForm.setSubmitting(false);
          } else {
            clearAll();
          }
          setLoading(false);
        },
        (reason: ApiError) => {
          setLoading(false);
          if (reason.status === 401 || reason.status === 403) {
            msgError('Acesso Negado, Reveja as suas credenciais!', true);
            loginForm.setFieldError('email', 'Acesso Negado! Reveja as suas credenciais!');
            loginForm.setSubmitting(false);
          } else {
            msgWarn(
              'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
              true
            );
          }
        }
      );
    },
  });

  const recuperarForm = useFormik<SendEmailResetRequest>({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Obrigatório').email('Email Inválido!'),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      UtilizadorService.utilizadorResetPasswordCreate({ requestBody: values }).then(
        () => {
          setEmailRecuperarEnviado(true);
          setLoading(false);
        },
        () => {
          setLoading(false);
          navigate('/');
          msgWarn(
            'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
            true
          );
        }
      );
    },
  });

  if (loading) {
    return <FullPageLoader />;
  }
  return (
    <div className='flex align-items-center justify-content-center'>
      <div className='surface-card p-4 shadow-2 border-round w-full lg:w-6'>
        <div className='text-center mb-5'>
          <SlideinLogo />
          {!recuperarMode && (
            <>
              <div className='text-900 text-3xl font-medium my-3'>Bem-vindo!</div>
              <span className='text-600 text-xl font-medium line-height-3'>
                Ainda não tens conta?
              </span>
              <Link
                className='font-medium no-underline ml-2 text-2xl text-blue-500 cursor-pointer white-space-nowrap'
                to={'registar' + grupoCode}>
                Cria aqui!
              </Link>
            </>
          )}
          {recuperarMode && (
            <>
              <div className='text-900 text-3xl font-medium my-3'>Recuperar password!</div>
            </>
          )}
        </div>
        <form
          onSubmit={loginForm.handleSubmit}
          className={classNames({ hidden: recuperarMode })}>
          <label
            htmlFor='email'
            className={classNames('block text-900 font-medium mb-2', {
              'p-error': !!(loginForm.touched['email'] && loginForm.errors['email']),
            })}>
            Email
          </label>
          <InputText
            id='email'
            type='email'
            onChange={loginForm.handleChange}
            placeholder='Endereço Email'
            className='w-full mb-3'
          />
          {loginForm.touched['email'] && loginForm.errors['email'] ? (
            <small className='p-error'>{loginForm.errors['email']}</small>
          ) : undefined}

          <label
            htmlFor='password'
            className={classNames('block text-900 font-medium mb-2', {
              'p-error': !!(loginForm.touched['password'] && loginForm.errors['password']),
            })}>
            Password
          </label>
          <Password
            id='password'
            type='password'
            autoComplete={'current-password'}
            toggleMask
            feedback={false}
            inputStyle={{ width: '100%' }}
            placeholder='Password'
            onChange={(e) => {
              loginForm.setFieldValue('password', e.target.value);
            }}
            className='w-full mb-3'
          />
          {loginForm.touched['password'] && loginForm.errors['password'] ? (
            <small className='p-error'>{loginForm.errors['password']}</small>
          ) : undefined}

          <div className='flex align-items-center justify-content-between mb-6'>
            <a
              className='font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer'
              onClick={() => setRecuperarMode(true)}>
              Recuperar password?
            </a>
          </div>

          <Button
            label='Entrar'
            icon='pi pi-user'
            className='w-full'
            type='submit'
          />
        </form>
        {recuperarMode && emailRecuperarEnviado && (
          <div className={'flex flex-column justify-content-center mb-4 text-xl '}>
            <div className={'mx-3'}>
              <p className={'font-semibold'}>Foi enviada uma mensagem para o teu e-mail.</p>
              <p>Segue as instruções para recuperares a tua password.</p>
              <p>
                O e-mail pode demorar alguns minutos, se não recebeste o e-mail, verifica na caixa
                de SPAM primeiro.
              </p>
              <p>
                Se as dificuldades persistirem contacta: <br />
                <a
                  className={'align-self-center'}
                  href={
                    'mailto:' +
                    process.env.REACT_APP_EMAIL_SUPORTE +
                    '?subject = Dificuldades Login&body = ...'
                  }>
                  {process.env.REACT_APP_EMAIL_SUPORTE}
                </a>
              </p>
            </div>
            <div className='flex align-items-center justify-content-end mb-6'>
              <Button
                outlined
                label={'Voltar'}
                className='font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer text-right'
                onClick={() => setRecuperarMode(false)}
              />
            </div>
          </div>
        )}
        {recuperarMode && !emailRecuperarEnviado && (
          <form onSubmit={recuperarForm.handleSubmit}>
            <label
              htmlFor='email'
              className={classNames('block text-900 font-medium mb-2', {
                'p-error': !!(recuperarForm.touched['email'] && recuperarForm.errors['email']),
              })}>
              Email
            </label>
            <InputText
              id='email'
              autoComplete={'email'}
              type='email'
              onChange={recuperarForm.handleChange}
              placeholder='Endereço Email'
              className='w-full mb-3'
            />
            {recuperarForm.touched['email'] && recuperarForm.errors['email'] ? (
              <small className='p-error'>{recuperarForm.errors['email']}</small>
            ) : undefined}

            <div className='flex align-items-center justify-content-between mb-6'>
              <a
                className='font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer'
                onClick={() => {
                  setRecuperarMode(false);
                  setEmailRecuperarEnviado(false);
                }}>
                Cancelar?
              </a>
            </div>

            <Button
              label='Recuperar'
              icon='pi pi-user'
              className='w-full'
              type='submit'
            />
          </form>
        )}
      </div>
    </div>
  );
}
