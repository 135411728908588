import { ProgressSpinner } from 'primereact/progressspinner';

export default function FullPageLoader() {
  return (
    <>
      <div className='flex flex-col align-items-center justify-items-center min-h-screen text-gray-800'>
        <ProgressSpinner
          style={{ width: '150px', height: '150px' }}
          strokeWidth='8'
          fill='var(--surface-ground)'
          animationDuration='.5s'></ProgressSpinner>
      </div>
    </>
  );
}
